<!-- teachers -->
<template>
  <div>
    <section-list
      title="Teachers"
      :data="sectionListData"
      v-loading="loading"
      :iconButton=" ($buttonPermission('Class', 'Full') ||
	              $buttonPermission('Class', 'Edit'))"
	  
	  
	 
      :notAllowed="sectionListData.length <= 0"
      @handleClick="clickTeacher"
      @editStaffCallback="editStaffCallback"
	  :isAutoBack='true'
	  back="Add a teacher in this page."
    >
	 <template slot="back">
	    <img
	      style="width: 100%"
	      :src="require('@/assets/images/moments/empty_teacher.svg')"
	    />
	  </template>
    </section-list>
    <SelectDialog
      v-model="isSelectAddShow"
      title="Add teachers"
      label="fullName"
      :showCheckbox="false"
      :data="allStaffList"
      @submit="changeAddSelect"
      @change="changeAdd"
    />
    <SelectDialog
      v-model="isSelectRemoveShow"
      :title="`Remove teacher`"
      label="fullName"
      :showCheckbox="true"
      :showSelect="false"
      :data="removeListData"
      submitText="Remove"
      @submit="changeRemoveSelect"
      @change="changeRemove"
    />
    <gg-Dialog
      :append-to-body="false"
      :visible.sync="isDialogShow"
      title="Remove teacher"
    >
      <div class="dialog-content">
        <p class="desc">
          Are you sure that you want to remove these teachers?
        </p>
        <ul class="content">
          <li v-for="(item, index) in deleteData" class="item" :key="index">
            <div class="user-img">
              <head-img
                class="user-box"
                :src="item.avatar"
                :firstName="item.firstName"
                :lastName="item.lastName"
                line-height="36"
              ></head-img>
            </div>
            <div class="userInfo">
              <p class="user-name">{{ item.firstName }} {{ item.lastName }}</p>
              <p class="user-email">{{ item.emailAddress }}</p>
            </div>
          </li>
        </ul>
      </div>
      <span slot="footer" class="dialog-footer">
        <gg-cancel-button @click="isDialogShow = false"
          >Cancel</gg-cancel-button
        >
        <gg-submit-button @click="handleRemove">Remove</gg-submit-button>
      </span>
    </gg-Dialog>
    <Snackbar :visible.sync="isSnackbarShow" :content="snackbarData" />
  </div>
</template>

<script>
import { Ajax } from "@/common";
import { mapState } from "vuex";
import { lightFormat } from "date-fns";
export default {
  components: {},
  name: "teachers",
  data() {
    return {
      activeIndex: "teachers",
      sectionListData: [],
      removeListData: [],
      isSelectAddShow: false,
      isSelectRemoveShow: false,
      desc: "0 enrolled students for the class size of 4",
      allStaffList: [],
      isDialogShow: false,
      selesetArr: [],
      deleteData: [],
      isSnackbarShow: false,
      snackbarData: "",
      loading: false,
    };
  },
  computed: {
    ...mapState({
      breadcrumb: (state) => state.breadcrumb,
      user: (state) => state.user,
    }),
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      // let classId = to.params.id;
      // let schoolName = vm.user.schoolName;
      // vm.breadcrumbData = vm.breadcrumb.breadcrumbData;
    });
  },
  methods: {
    _getData() {
      var data = {
        id: this.user.classId,
      };
      this.loading = true;
      Ajax.get("/usermanage/classRef/selectStaffByClassId", data).then(
        (res) => {
          let arr = [];
          this.sectionListData = res.data || [];
          // 赋值删除staff的列表
          res.data.forEach((item) => {
            arr.push({
              id: item.userId,
              avatar: item.photo,
              firstName: item.firstName,
              lastName: item.lastName,
              fullName: `${item.firstName} ${item.lastName}`,
              email: item.emailAddress,
              isUser: true,
            });
          });
          this.removeListData = arr;
          this.loading = false;
		  this._getAllStaff()
        }
      );
    },
    _getAllStaff() {
      this.loading = true;
      //todo
      Ajax.get(
        `/usermanage/staff/getStaffByStaffId/${this.user.locationId}/${this.user.classId}`
      ).then((res) => {
        let response = res.data || [];
        let arr = [];
        response.forEach((item) => {
          arr.push({
            id: item.userId,
            avatar: item.photo,
            firstName: item.firstName,
            lastName: item.lastName,
            fullName: `${item.firstName} ${item.lastName}`,
            email: item.emailAddress,
            isUser: true,
          });
        });
        this.allStaffList = arr;
        this.loading = false;
      });
    },
    menuSelect(key, item) {
      console.log(key, item);
      this.activeIndex = key;
      this.$router.push(`/${key}`);
    },
    editStaffCallback(isAddStaff) {
      this.isSelectAddShow = isAddStaff;
	  if(this.sectionListData.length <= 0){
	  		  return false
	  }
      this.isSelectRemoveShow = !isAddStaff;
      // if (isAddStaff) {
      //   this._getAllStaff();
      // }
    },
    changeAddSelect(selesetArr) {
      let arr = [];
      selesetArr.forEach((item) => {
        arr.push(item.id);
      });
      let data = {
        classId: this.user.classId,
        companyId: this.user.companyId,
        locationId: this.user.locationId,
        staffIds: arr,
        stuIds: [],
        type: "active",
      };
      this.loading = true;
      Ajax.post("/usermanage/classRef/saveTeacherClassRef", data).then(
        (res) => {
			
			this.isDialogShow = false;
			this.isSnackbarShow = true;
			this.snackbarData = `${arr.length==1?'':arr.length} ${arr.length>1? 'teachers have':'Teacher has'}  been added`;
          this._getData();
          this.loading = false;
        }
      );
    },
    changeRemoveSelect(selesetArr) {
      this.isDialogShow = true;
      this.deleteData = selesetArr;
	   console.log(this.deleteData);
    },
    handleRemove() {
      console.log(this.deleteData);
      // 根据ID区分删除重复的
      // let temArr = [];
      // this.sectionListData = this.sectionListData.filter((item, index) => {
      //   this.deleteData.forEach((item2) => {
      //     temArr.push(item2.id);
      //   });
      //   return temArr.indexOf(item.id) == -1;
      // });
      // 删除老师
      let arr = [];
      this.deleteData.forEach((item) => {
        arr.push(item.id);
      });
      let data = {
        classId: this.user.classId,
        companyId: this.user.companyId,
        locationId: this.user.locationId,
        staffIds: arr,
        stuIds: [],
        type: "closed",
      };
      this.loading = true;
      Ajax.post("/usermanage/classRef/removeStaffOfClass", data).then((res) => {
        this.loading = false;
        this.isDialogShow = false;
        this.isSnackbarShow = true;
        this.snackbarData = `${this.deleteData.length==1?'':this.deleteData.length} ${this.deleteData.length>1? 'teachers have':'Teacher has'}  been removed`;
        this._getData();
      });
    },
    changeAdd() {},
    changeRemove() {},
    clickTeacher(item) {
      console.log(item);
      // 跳转班级老师之前设置面包屑数据
      let className = this.user.className;
      let schoolName = this.user.schoolName;
      sessionStorage.setItem(
        "staffInfo",
        JSON.stringify({
          userId: item.userId,
          type: "1",
        })
      );
      this.$store.commit("SET_BREADCRUMDATA", [
        {
          routeName: "locations",
          name: schoolName,
        },
        {
          routeName: "classes",
          name: "classes",
        },
        {
          routeName: "classDetailInfo",
          name: className,
        },
        {
          name: `${item.firstName} ${item.lastName}`,
        },
      ]);
      this.$router.push({ name: "staffPersonalInfo" ,query:{class:true}});
    },
  },
  created() {
    this._getData();
    this._getAllStaff();
  },
};
</script>
<style lang='less' scoped>
/deep/.el-dialog__title {
  font-family: OpenSans-bold;
}
.dialog-content {
  color: #202124;
  .desc {
    margin: 18px 0 26px;
  }
  .content {
    .item {
      width: 100%;
      height: 100%;
      margin-bottom: 20px;
      padding-left: 0;
      display: flex;
      align-items: center;
      font-family: Roboto;
      line-height: 20px;
      .user-img {
        margin-right: 20px;
      }
    }
  }
}
/deep/.el-dialog{
	width: 580px;
}
/deep/.dialog-content .desc{
	font-family: Roboto;
}
</style>