<!-- classDetail -->
<template>
  <div>
   <!-- <gg-page-header
      :title="classData.className"
      :squareInfo="{
        avatar: classData.classPhoto,
      }"
      :menuData="menuBarData"
      @menuCallback="menuClick"
    >
      <div slot="info">
        <div
          class="title-status mar-b-0"
          :class="{ 'title-status-red': classData.status === 'closed' }"
        >
          {{ classData.status | firstUpperCase }}
        </div>
      </div>
    </gg-page-header> -->
	<pagehead1
	:title="classData.className"
	:squareInfo="{
	  avatar: classData.classPhoto,
	}"
	:menuData="menuBarData"
	@menuCallback="menuClick"
	>
		<div slot="info">
		  <div
		    class="title-status mar-b-0"
		    :class="{ 'title-status-red': classData.status === 'closed' }"
		  >
		    {{ classData.status | firstUpperCase }}
		  </div>
		</div>
<!-- 		<template slot="menus">
						<gg-menu-bar-item v-for="menuItem in menuBarData"
							@click="menuClick(item, menuItem, index)" :key="menuItem.key" :class="{
		  line:  menuItem.key == 'edit' 
		}" 

		>
							{{ menuItem.label }}1
						</gg-menu-bar-item>
					</template> -->
	</pagehead1>
    <border-less-table
      :data="topData"
      v-loading="loading"
    />
	<BaseFormDialog1
	    v-if="isShowBaseFormDialog"
	    v-model="isShowBaseFormDialog"
	    :title="baseFormTitle"
	    :data="dialogClassData"
	    @submit="handleSaveClass"
	    textReminder="* indicates a required field"
	 
	    :submitBtnTxt="submitBtnTxt"
	  >
	    <template slot="renderHtml" slot-scope="scope">
	      <baseGGSelect
	        v-if="scope.data.key == 'level'"
	        v-model="scope.data.value"
	        ref="level"
	        :placeholder="scope.data.placeholder"
	      >
	        <md-option
	          v-for="(item, index) in levelList"
	          :value="item.value"
	          :key="index"
	        >
	          {{ item.value }}
	        </md-option>
	      </baseGGSelect>
	      <baseGGSelect
	        v-else-if="scope.data.key == 'subject'"
	        v-model="scope.data.value"
	        ref="subject"
	        :placeholder="scope.data.placeholder"
	      >
	        <md-option
	          v-for="(item, index) in subjectList"
	          :value="item.value"
	          :key="index"
	        >
	          {{ item.value }}
	        </md-option>
	      </baseGGSelect>
	      <baseGGSelect
	        v-else-if="scope.data.key == 'year'"
	        v-model="scope.data.value"
	        ref="year"
	        :placeholder="scope.data.placeholder"
	      >
	        <md-option
	          v-for="(item, index) in 4"
	          :value="currentYear + index"
	          :key="index"
	        >
	          {{ currentYear + index }}
	        </md-option>
	      </baseGGSelect>
	      <baseGGSelect
	        v-else-if="scope.data.key == 'room'"
	        v-model="scope.data.value"
	        ref="room"
	        :placeholder="scope.data.placeholder"
	      >
	        <md-option
	          v-for="(item, index) in scope.data.label"
	          :value="item"
	          :key="index"
	        >
	          {{ item }}
	        </md-option>
	      </baseGGSelect>
	    </template>
	  </BaseFormDialog1>
	</GgPage>
	<imgCutModal
	  :visible.sync="isImgCutShow"
	  v-if="isImgCutShow"
	  @_uploadCallback="_uploadCallback"
	/>
    <Snackbar :visible.sync="snackbar.isShow" :content="snackbar.content" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import { Ajax, Utils } from "@/common";
import pagehead1 from '../../components/edoovo-components/GgPageHeader/index1.vue'
import moment from 'moment'
export default {
	components:{
		pagehead1
	},
  name: "classDetail",
  data() {
    return {
		topData:[
      ],
      menuDatas: [
        {
          name: "NB-2",
          key: "NB-2",
          active: true,
          children: [
            { name: "Teachers", key: "teachers" },
            { name: "Students", key: "students" },
          ],
        },
      ],
      activeIndex: "Teachers",
      classData: {},
      loading: false,
      snackbar: { isShow: false, content: "" },
	  menuBarData: [
	    { label: "Upload photo", key: "upload" },
		{ label: "Edit", key: "edit" },
	    { label: "Close", key: "closed" },

	    { label: "Delete", key: "delete", disabled: true },
	  ],
	  classList: [],
	  isShowBaseFormDialog: false,
	  baseFormTitle: "Create new class",
	  submitBtnTxt: "Create",
	  renderPass: false,
	  dialogClassData: [
	    {
	      placeholder: "Class name *",
	      key: "className",
	      value: "",
	      reg: /^[\S\s]{1,40}$/,
	      errorText: "Character limit of 40",
	    },
	    {
	      placeholder: "Level",
	      key: "level",
	      float: true,
	      value: "",
	      label: ["Preschool", "English", "Maths", "Chinese"],
	      renderHtml: true,
	    },
	    {
	      placeholder: "Subject",
	      key: "subject",
	      float: true,
	      value: "",
	      label: ["Mixed", "English", "Maths", "Chinese"],
	      renderHtml: true,
	    },
	    {
	      placeholder: "Section",
	      key: "section",
	      value: "",
	    },
	    {
	      placeholder: "Year",
	      key: "year",
	      float: true,
	      value: "",
	      label: ["2021", "2020", "2019", "2018"],
	      renderHtml: true,
	    },
	    {
	      placeholder: "Room",
	      key: "room",
	      value: "",
	    },
	  ],
	  slotData: ["level", "subject", "year"],
	  isImgCutShow: false,
	  snackbar: { isShow: false, content: "" },
	  pageNumber: 1,
	  pageSize: 20,
	  totalNum: 0,
	  itemData: {}, // 当前点击的item信息
	  loading: false,
	  levelList: [],
	  subjectList: [],
	  currentYear: new Date().getFullYear(),
    };
  },
  filters: {
    firstUpperCase: Utils.firstUpperCase,
  },
  computed: {
    ...mapState({
      breadcrumb: (state) => state.breadcrumb,
      user: (state) => state.user,
    }),
	
  },
  created() {
    this._getData();
	this._getSettingList("classes", "level", "levelList");
	this._getSettingList("classes", "subject", "subjectList");
  },
  watch: {
    dialogClassData: {
      deep: true,
      handler() {
        this.$nextTick(() => {
          let aaa = this.slotData.every(
            (item) => this.$refs[item] && !!this.$refs[item].value
          );
          this.renderPass = aaa;
          console.log("renderPass:", this.renderPass);
        });
      },
    },
  },
  methods: {
	  _uploadCallback(formData, fileData) {
	    formData.append("multipartFile", fileData.raw);
	    Ajax.post("/media/public/file/upload", formData)
	      .then((res) => {
	        //
	        this.$set(
	          this.classData,
	          "classPhoto",
	          `${process.env.VUE_APP_fileBasePath}${res.data.filePath}`
	        );
	        this.handleSaveClass();
	        this.isImgCutShow = false;
	      })
	      .catch(() => {
	        this.isImgCutShow = false;
	      });
	  },
	  _getSettingList(dataLevel, dataType, datalistName) {
	    let data = {
	      userId: this.user.userId,
	      dataLevel,
	      dataType,
	    };
	    this.loading = true;
	    Ajax.post("/usermanage/setting/getList", data)
	      .then((res) => {
	        let response = res.data;
	        this.loading = false;
	        this[datalistName] = response || [];
	      })
	      .catch(() => {
	        this.loading = false;
	      });
	  },
    _getData() {
      var data = {
        id: this.$route.query.classId,
      };
      this.loading = true;
      Ajax.get("/usermanage/class/selectByIdV1", data).then((res) => {
        this.classData = res.data || {};
		if (res.data.status === "closed") {
		  this.menuBarData = [
		    { label: "Restore", key: "restore" },
		    { label: "Delete", key: "delete" },
		  ];
		} else {
		  this.menuBarData = [
		    { label: "Upload photo", key: "upload" },
				    { label: "Edit", key: "edit",line:true },
		    { label: "Close", key: "closed" },
			
		    { label: "Delete", key: "delete" },
		  ];
		}
		if(res.data.using){
			this.menuBarData.forEach((item)=>{
				if(item.key=='delete'){
					item.isDisabled=true
				}
			})
		}
		let menuBarData = this.menuBarData;
		if (this.$buttonPermission("Class", "Full")) {
		  this.menuBarData = menuBarData;
		} else if (this.$buttonPermission("Class", "Edit")) {
		  this.menuBarData = this.menuBarData.filter(
		    (item) => item.key !== "delete" && item.key !== "closed" && item.key !== "restore"
		  );
		}else{
			 this.menuBarData = []
		}
		if(this.classData.status=='active'){
			this.topData = [
			{ label: 'Level', value: this.classData.level || '-' },
			{ label: 'Subject', value: this.classData.subject || '-' },
			{ label: 'Section', value: this.classData.section || '-' },
			{ label: 'Year', value: this.classData.year || '-' },
			 { label: 'Created on', value: moment(this.classData.createTime).format('DD/MM/YYYY') || '-' },
			{ label: 'Room', value: this.classData.room || '-' }]
		}else{
			this.topData = [
			{ label: 'Level', value: this.classData.level || '-' },
			{ label: 'Subject', value: this.classData.subject || '-' },
			{ label: 'Section', value: this.classData.section || '-' },
			{ label: 'Year', value: this.classData.year || '-' },
			 { label: 'Created on', value: moment(this.classData.createTime).format('DD/MM/YYYY') || '-' },
			 { label: 'Closed on', value: moment(this.classData.lastClosedTime).format('DD/MM/YYYY') || '-' },
			{ label: 'Room', value: this.classData.room || '-' }]
		}
		
        this.loading = false;
      });
    },
    menuSelect(key, item) {
      console.log(key, item);
      this.activeIndex = key;
      this.$router.push(`/${key}`);
    },
    menuClick(menuItem) {
		menuItem=menuItem.label
		this.fillData()
      this.$emit("menuCallback", menuItem);
      if (menuItem == "Restore") {
        let messageObj = {
          title: "Restore class?",
          desc: "Once restored, the class will move to active.",
          cancelTxt: "Cancel",
          confirmTxt: "Restore",
        };
        let callback = () => {
          this.handleUpdateClass("active");
        };
        this.openMessageBox(messageObj, callback);
      } else if (menuItem == "Delete") {
        let messageObj = {
          title: "Delete class?",
          desc: "Once deleted, you will not be able to recover the data.",
          cancelTxt: "Cancel",
          confirmTxt: "Delete",
        };
        let callback = () => {
          this.handleUpdateClass("delete");
        };
        this.openMessageBox(messageObj, callback);
      }else if(menuItem == 'Edit'){
		 
		  this.isShowBaseFormDialog = true;
		  this.baseFormTitle = "Edit class";
		  this.submitBtnTxt = "Save";
		  
		  this.renderPass = false;
	  }else if (menuItem== "Upload photo") {
        this.isImgCutShow = true;
      }else if (menuItem == "Close") {
        let messageObj = {
          title: "Close class?",
          desc:
            "Once the class is closed, you will not be able to operate the class for students in this class unless you restore it.",
          cancelTxt: "Cancel",
          confirmTxt: "Close",
		  showClose:false,
        };
        let callback = () => {
          this.handleUpdateClass("closed");
        };
        this.openMessageBox(messageObj, callback);
      }
    },
	fillData() {
	  this.dialogClassData.forEach((item) => {
	    item.value = this.classData[item.key];
	  });
	  console.log(this.dialogClassData)
	},
	handleSaveClass() {
	  let baseData = {
	    companyId: this.user.companyId,
	    locationId: this.user.locationId,
	    userId: this.user.userId,
	    current: 0,
	    size: 10,
	  };
	  let formData = {
	    className: "小萌",
	    level: "Preschool",
	    subject: "English",
	    section: 2,
	    year: "2021",
	    room: "room123",
	    id: this.classData.id,
	    classPhoto: this.classData.classPhoto,
	  };
	  let data = {};
	  this.dialogClassData.forEach((item) => {
	    formData[item.key] = item.value;
	  });
	  data = Object.assign({}, baseData, formData);
	
	  Ajax.post("/usermanage/class/updateClassV1", data).then((res) => {
		  if(status=='closed'){
			  this.handleSuspend("Class has been moved to closed");  
		  }else if(status=='active'){
			  this.handleSuspend("Class has been restored"); 
		  }else if(status=='delete'){
			  this.handleSuspend("Class has been deleted"); 
			 
		  }else{
			 this.handleSuspend("Class has been edited");  
		  }
	   
	    this._getData();
	  });
	},
    handleUpdateClass(status) {
      let baseData = {
        companyId: this.user.companyId,
        locationId: this.user.locationId,
        userId: this.user.userId,
      };
      let formData = {
        id: this.$route.query.classId,
        status,
      };
      let data = {};
      data = Object.assign({}, baseData, formData);

      Ajax.post("/usermanage/class/updateClassV1", data).then((res) => {
        if(status=='closed'){
        			  this.handleSuspend("Class has been moved to closed");  
        }else if(status=='active'){
        			  this.handleSuspend("Class has been restored"); 
        }else if(status=='delete'){
			// setTimeout(()=>{
			// 	this.$router.go(-1)
			// },1000)
			sessionStorage.setItem('delete','class')
			this.$router.go(-1)
        			
        }else{
        			 this.handleSuspend("Class has been edited");  
        }
        this._getData();
      });
    },
    openMessageBox(messageObj, callback) {
      this.$confirm(messageObj.desc, messageObj.title, {
        cancelButtonText: messageObj.cancelTxt,
        confirmButtonText: messageObj.confirmTxt,
        cancelButtonClass: "gg_cancel_button",
        confirmButtonClass: "gg_submit_button",
        customClass: "gg_confirm",
        callback: (action) => {
          if (action == "confirm") {
            callback(action);
          }
        },
      });
      //  .then(() => {
      //   this.handleSuspend("dddd");
      // });
    },
    handleSuspend(content) {
      this.snackbar.content = content;
      this.snackbar.isShow = true;
    },
	openMessageBox(messageObj, callback) {
	     this.$confirm(messageObj.desc, messageObj.title, {
	       cancelButtonText: messageObj.cancelTxt,
	       confirmButtonText: messageObj.confirmTxt,
	       cancelButtonClass: "gg_cancel_button",
	       confirmButtonClass: "gg_submit_button",
	       customClass: "gg_confirm",
			showClose:false,
	       callback: (action) => {
	         if (action == "confirm") {
	           callback(action);
	         }
	       },
	     });
	     //  .then(() => {
	     //   this.handleSuspend("dddd");
	     // });
	   },
	  
  },
};
 
</script>
<style lang='less' scoped>
	/deep/ .el-image__inner{
		height: 100%;
		object-fit: cover!important;
	}
	/deep/.menu_bar_button{
		.iconmore_vert-24px{
			margin-top: 8px;
			display: inline-block;
			overflow:visible;
		}
	}
	/deep/.borderless-left .label{
		font-family: "Roboto-Medium";
		font-weight: normal;
	}
</style>